import React from 'react';

const testimonials = [
  {
    name: 'Rahul Sharma',
    position: 'Fantasy Cricket Enthusiast',
    comment: 'Cric Dan Fan is amazing! It’s super easy to use, and the contests are exciting. I’ve won multiple prizes and the withdrawals are fast!',
    image: 'https://static.toiimg.com/imagenext/toiblogs/photo/blogs/wp-content/uploads/2021/05/edit-Kiran-Jonnalagadda.jpg', // Replace with actual image URL
    rating: 5,
  },
  {
    name: 'Priya Gupta',
    position: 'Casual Player',
    comment: 'I love how secure and fun this platform is. The interface is intuitive, and the 24/7 support is very helpful.',
    image: 'https://www.shutterstock.com/image-photo/cheerful-young-indian-woman-outdoors-260nw-520322230.jpg',
    rating: 4,
  },
  {
    name: 'Amit Verma',
    position: 'Cricket Analyst',
    comment: 'This platform gives me a chance to test my cricket knowledge while earning rewards. Highly recommended!',
    image: 'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/35af6a41332353.57a1ce913e889.jpg',
    rating: 5,
  },
];

const Testimonals = ({handleClaimNowClick}) => {
  return (
    <div className="testimonials-container py-16 bg-gradient-to-br from-gray-500 to-gray-900 text-white">
      <h2 className="text-4xl font-extrabold text-center mb-10">
        What Our Users Say
      </h2>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8 px-6">
        {testimonials.map((testimonial, index) => (
          <div
            onClick={handleClaimNowClick}
            key={index}
            className="testimonial-card p-6 bg-gray-400 text-gray-800 rounded-xl shadow-lg hover:shadow-2xl transition transform hover:-translate-y-2"
          >
            <div className="flex flex-col items-center">
              <img
                src={testimonial.image}
                alt={`${testimonial.name}`}
                className="w-24 h-24 rounded-full mb-4 border-4 border-indigo-500"
              />
              <h3 className="text-xl font-bold">{testimonial.name}</h3>
              <p className="text-sm text-gray-500">{testimonial.position}</p>
            </div>
            <p className="text-gray-600 italic mt-4 text-center">
              "{testimonial.comment}"
            </p>
            <div className="flex justify-center mt-4">
              {'★'.repeat(testimonial.rating)}
              {'☆'.repeat(5 - testimonial.rating)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonals;
