import React from "react";

import Marquee from "react-fast-marquee";
// import { Link } from 'react-router-dom';
const Footer = ({handleClaimNowClick}) => {
  return (
    <footer className="bg-gray-900 mb-20 text-gray-300 py-8">
      <div className="container mx-auto px-4">
        {/* Top Section */}
        <div className="grid md:grid-cols-3 gap-6 text-center md:text-left">
          {/* Links Section */}
          <div>
            <h3 className="text-lg font-bold text-white mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <h1
                onClick={handleClaimNowClick}
                className="hover:text-indigo-400 transition duration-200">
                  Terms & Conditions
                </h1>
              </li>
              <li>
                <h1 
                handleClaimNowClick={handleClaimNowClick}
                className="hover:text-indigo-400 transition duration-200">
                  Privacy Policy
                </h1>
              </li>
              <li>
                <h1
               onClick={handleClaimNowClick}
                className="hover:text-indigo-400 transition duration-200">
                  Cookies policies
                </h1>
              </li>
              <li>
                <h1
                 onClick={handleClaimNowClick}
                  className="hover:text-indigo-400 transition duration-200"
                >
                  Disclaimer
                </h1>
              </li>
            </ul>
          </div>

          {/* Description Section */}
          <div>
            <h3 
            onClick={handleClaimNowClick}
            className="text-lg font-bold text-white mb-4">About</h3>
            <p className="text-sm">
              Cric Dan Fan is a skill-based platform for fantasy sports
              enthusiasts. This platform is strictly for users aged 18+.
            </p>
            <p className="mt-2 text-sm">
              No real money is involved, and the platform is designed purely for
              entertainment and learning purposes.
            </p>
          </div>

          {/* Contact Section */}
          <div>
            <h3
            onClick={handleClaimNowClick}
            className="text-lg font-bold text-white mb-4">Contact Us</h3>
            <p className="text-sm">Have questions? Reach out to us at:</p>
            <p className="text-indigo-400 mt-2">
              <h1 onClick={handleClaimNowClick}>support@cricdanfan.com</h1>
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-700 my-6"></div>

        {/* Bottom Section */}
        <div className="text-center text-sm">
          <p className="mb-2">© 2024 CricFans. All Rights Reserved.</p>
          <p className="text-red-500 text-lg font-bold">
            This platform does not support gambling or betting in any form.
            Participation is strictly for individuals aged 18+ and is purely
            skill-based.
          </p>
        </div>
      </div>
      {/* <Marquee autoFill>
        <span class="mx-4 text-lg font-semibold">🚀 Smooth Marquee Text</span>
        <span class="mx-4 text-lg font-semibold">🌟 Tailwind CSS is Cool!</span>
        <span class="mx-4 text-lg font-semibold">🔥 Build Interactive UI!</span>
        <span class="mx-4 text-lg font-semibold">🚀 Smooth Marquee Text</span>
        <span class="mx-4 text-lg font-semibold">🌟 Tailwind CSS is Cool!</span>
        <span class="mx-4 text-lg font-semibold">🔥 Build Interactive UI!</span>
      </Marquee> */}
    </footer>
  );
};

export default Footer;
