import React, { useEffect } from "react";
import { FaLightbulb } from "react-icons/fa";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { TfiCup } from "react-icons/tfi";
import AOS from "aos";
import "aos/dist/aos.css";

const Whyplayfantasy = ({handleClaimNowClick}) => {
    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: "ease-in-out",
          once: "false",
        });
      }, []);
  const reasons = [
    {
      title: "Feel the Thrill of Every Match",
      description:
        "Whether it’s an international tournament or a local T20 league, fantasy cricket keeps you on the edge of your seat.",
      icon: <BsFillLightningChargeFill className="text-[8rem] text-red-500 " />,
    },
    {
      title: "Test Your Cricket Knowledge",
      description:
        "Analyze player performance, pitch conditions, and team strategy to build your dream team.",
      icon: <FaLightbulb className="text-[8rem] text-yellow-500 " />,
    },
    {
      title: "Win Real Rewards",
      description:
        "Participate in contests to earn real cash rewards, exciting prizes, and bragging rights.",
      icon: <TfiCup className="text-[8rem] text-green-500 " />,
    },
  ];

  return (
    <div className="why-play-container p-6 bg-[#F0F0F0] text-center">
      <h2 className="text-5xl mt-10 font-bold mb-4">
        Why Play Fantasy Cricket on Cric Dan Fan?
      </h2>
      <p className="text-xl text-gray-700 mb-6">
        Experience the ultimate thrill of cricket by playing fantasy sports.
        Here’s why millions of fans love our fantasy cricket platform!
      </p>
      <div className="grid md:grid-cols-3 px-20 mt-20 gap-6">
        {reasons.map((reason, index) => (
          <div
          onClick={handleClaimNowClick}
            data-aos="fade-right"
            key={index}
            className="reason-card p-5  grid place-items-center cursor-pointer rounded-lg  hover:shadow-lg transition"
          >
            <li className="text-center list-none mb-5">{reason.icon}</li>
            <h3 className="text-xl font-semibold mb-2">{reason.title}</h3>
            <p className="text-gray-600">{reason.description}</p>
          </div>
        ))}
      </div>

      <button
      onClick={handleClaimNowClick}
      className="text-center mt-10 bg-yellow-500 px-20 py-3 text-white rounded-md uppercase"> start playing now</button>
    </div>
  );
};

export default Whyplayfantasy;
