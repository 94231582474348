// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// import Homepage from "../../phantom/src/Components/crickleads/Homepage";
// import Homepage from "./Components/Diamondexchnage/Homepage";
// import Landingpage from "../../phantom/src/Components/crickleads/Homepage"
import Homepage from "../../phantom/src/Components/cricfans/Homepage";
// import Homepage from "./Components/WickBuzz/Homepage";
// import Homepage from "./Components/khelocricket/homepage";
// import Homepage from "./Components/WickBuzz/Homepage";

// import Homepage from "./Components/khelocricket/homepage";
// import PrivacyPolicy from "./Components/crickleads/Privacypolicy";
// import TermsConditions from "./Components/crickleads/Terms&conditions";
// import Cookiespolicy from "./Components/crickleads/Cookies";
// import Navbar from "./Components/crickleads/Navbar";
// import Footer from "./Components/crickleads/Footer";
// import ScrollToTop from "./Components/crickleads/Scrollontop";

function App() {
  return (
    // <Router>
    //   <div className="App">
    //     <ScrollToTop />
    //     <Navbar />
    //     <Routes>
    //       <Route path="/" element={<Homepage />} />
    //       <Route path="/privacy" element={<PrivacyPolicy />} />
    //       <Route path="/terms" element={<TermsConditions />} />
    //       <Route path="/cookies" element={<Cookiespolicy />} />
    //     </Routes>
    //     <Footer />
    //   </div>
    // </Router>
    <div>
      <Homepage/>
    </div>
  );
}

export default App;
