import React,{useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { FaRegCalendarAlt, FaUserFriends, FaTrophy, FaClipboardList, FaChartLine, FaHandHoldingUsd, FaRedoAlt } from 'react-icons/fa';

const Howitworks = ({handleClaimNowClick}) => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: "ease-in-out",
          once: "false",
        });
      }, []);
  const steps = [
    {
      step: 'Step 1: Select a Match',
      description:
        "Choose a match from the list of upcoming cricket games. You'll find both international and domestic tournaments available for selection.",
      icon: <FaRegCalendarAlt className="text-blue-500 text-6xl mb-5" />,
    },
    {
      step: 'Step 2: Create Your Team',
      description:
        'Use your cricket knowledge to build the perfect team. Select 11 players from both teams, ensuring a balance between batsmen, bowlers, and all-rounders.',
      icon: <FaUserFriends className="text-green-500 text-6xl mb-5" />,
    },
    {
      step: 'Step 3: Choose Captain & Vice Captain',
      description:
        'Pick a captain and vice-captain for your team wisely. Your captain earns 2x points, while the vice-captain earns 1.5x points.',
      icon: <FaTrophy className="text-yellow-500 text-6xl mb-5" />,
    },
    {
      step: 'Step 4: Join the Contest',
      description:
        'Once your team is ready, join a contest. You can participate in paid contests to win cash rewards or free contests to practice your skills.',
      icon: <FaClipboardList className="text-purple-500 text-6xl mb-5" />,
    },
    {
      step: 'Step 5: Track Your Team\'s Performance',
      description:
        'Track your team\'s performance in real-time as the match progresses. You’ll be able to see live points and where your team stands in the leaderboard.',
      icon: <FaChartLine className="text-red-500 text-6xl mb-5" />,
    },
    {
      step: 'Step 6: Win Points & Rewards',
      description:
        'Earn points based on your players\' real-life performances. Higher the points, better your chances to win cash rewards or prizes.',
      icon: <FaHandHoldingUsd className="text-indigo-500 text-6xl mb-5" />,
    },
    {
      step: 'Step 7: Withdraw Your Winnings',
      description:
        'If you’ve won, you can withdraw your winnings securely through Paytm, UPI, or bank transfer. Fast, secure, and easy withdrawals.',
      icon: <FaHandHoldingUsd className="text-teal-500 text-6xl mb-5" />,
    },
    {
      step: 'Step 8: Play Again',
      description:
        'Had fun? Join another contest and repeat the excitement. You can play multiple matches and contests to maximize your chances of winning.',
      icon: <FaRedoAlt className="text-orange-500 text-6xl mb-5" />,
    },
  ];

  return (
    <div className="how-it-works-container p-6 bg-gray-900 text-center">
      <h2 className="text-3xl  text-white font-bold mb-4">How to Play Fantasy Cricket</h2>
      <p className="text-lg text-white mb-6">
        Follow these simple steps to participate in exciting fantasy cricket contests and win big!
      </p>
      <div className="grid md:grid-cols-3 gap-6">
        {steps.map((item, index) => (
          <div
          onClick={handleClaimNowClick}
           data-aos="fade-down"
            key={index}
             className="flex flex-col items-center p-5 text-white bg-gray-800 rounded-lg shadow-lg"
          >
            <div className='grid place-items-center'>
            <div className='text-center'>{item.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{item.step}</h3>
              <p className="">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Howitworks;
