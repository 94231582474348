import React from "react";
import { FaUsers } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { MdHealthAndSafety } from "react-icons/md";

const Features = ({handleClaimNowClick}) => {
  const features = [
    {
      title: "3M+ Registered Users",
      description:
        "Join a massive and growing community of fantasy sports enthusiasts across India.",
      icons: <FaUsers className="text-center text-yellow-500 text-6xl " />,
    },
    {
      title: "No Platform Fee",
      description:
        "We don't charge any platform fees, so you can enjoy playing and winning without hidden costs.",
      icons: <FaMoneyBillWave className="text-green-500 text-6xl" />,
    },

    {
      title: "24/7 Support",
      description:
        "Our support team is available round the clock to assist you with any issues or queries.",
      icons: <BiSupport className="text-blue-500 text-6xl" />,
    },
    {
      title: "100% Safe & Secure",
      description:
        "Your data and transactions are protected by industry-standard security measures.",
      icons: <MdHealthAndSafety className="text-blue-200 text-6xl" />,
    },
  ];

  return (
    <div className="features-container p-0   bg-[#22333B] text-center">
      <div className="grid md:grid-cols-4 gap-6">
        {features.map((feature, index) => (
          <div
          onClick={handleClaimNowClick}
            key={index}
            className="feature-card text-center p-4  rounded-lg  cursor-pointer grid place-items-center  text-white hover:shadow-lg transition"
          >
            <li className="list-none text-center">{feature.icons}</li>
            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
            <p className="">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
