import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";


const Hero = ({handleClaimNowClick}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: "false",
    });
  }, []);
  return (
    <div
      className="w-full h-[90vh] z-0 bg-no-repeat"
      style={{
        backgroundImage: "url('/backgrouns sa.jpg')",
        backgroundSize: "cover",
      }}
    >
      <div className="absolute inset-0 top-0  bg-black opacity-35  z-0 "></div>
  
      <div className="flex justify-center items-center text-center">
        <div className="pt-20 absolute z-50 top-12  md:pl-10 md:w-[1000px]">
          <h1
            data-aos="fade-right"
            className="md:text-[4rem] text-[3rem] font-monstratitalic text-transparent   text-white "
          >
            INDIA'S MOST TRUSTED CRICKET FANTASY APP DOWNLOAD NOW
          </h1>
          <p className="text-yellow-500 font-bold  text-2xl">
            🏆 Participate in fantasy sports for a golden opportunity to win
            daily prizes! 🏏
          </p>
          <button 
          onClick={handleClaimNowClick}
          className="bg-gradient-to-r from-black to-[#0F1D6B] border-2 border-white text-white px-20 mt-20 py-3 uppercase rounded-xl">
             click here to Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
