import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Hero from "./Hero";
import Features from "./Fetures";
import Whyplayfantasy from "./Whyplayfantasy";
import Howitworks from "./Howitworks";
import Footer from "./Footer";
import Testimonals from "./Testimonals";
import Getid from "./Getid";
import Marquee from "react-fast-marquee";
const Homepage = () => {
  useEffect(() => {
    ReactGA.initialize("G-WMH23T5DYK"); 
    ReactGA.send("pageview"); 
  }, []);

  const handleClaimNowClick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://wa.link/ggnow", "_blank");
  };
  
  return (
    <div className="">
      <Marquee autoFill className="bg-black text-white  p-5 fixed top-0 z-35">
        <span class="mx-4 text-lg font-semibold">🚀 Get 10% Discount </span>
        <span class="mx-4 text-lg font-semibold">🌟 Get 10% Discount</span>
        <span class="mx-4 text-lg font-semibold">🔥 Get 10% Discount</span>
        <span class="mx-4 text-lg font-semibold">🚀 Get 10% Discount</span>
        <span class="mx-4 text-lg font-semibold">🌟 Get 10% Discount</span>
        <span class="mx-4 text-lg font-semibold">🔥 Get 10% Discount</span>
      </Marquee>
      <Hero handleClaimNowClick={handleClaimNowClick} />
      <Features handleClaimNowClick={handleClaimNowClick} />
      <Whyplayfantasy handleClaimNowClick={handleClaimNowClick} />
      <Howitworks handleClaimNowClick={handleClaimNowClick} />
      <Testimonals handleClaimNowClick={handleClaimNowClick} />
      <Footer handleClaimNowClick={handleClaimNowClick} />
      <Getid handleClaimNowClick={handleClaimNowClick} />
    </div>
  );
};

export default Homepage;
